<template>
<v-container>
    <v-row dense align-content="center">
        <v-col cols="5">
            <span class="display-1 font-weight-black">{{licensePlate}}</span>
        </v-col>
        <v-col cols="5" class="d-flex justify-end">
            <span><v-icon large color="green darken-2">speed</v-icon></span>
            <!-- Speed -->
            <span class="text-md-body-1 font-weight-black">
                {{speed}} km/h
            </span>
        </v-col>
    </v-row>
    <v-row dense>
        <v-col cols="12">
            <!-- Address -->
            <span><v-icon small color="green">location_city</v-icon></span>&nbsp;
            <span class="text-caption">{{address}}</span>
            <span>
                <v-btn icon @click.native="reverseGeoCode">
                    <v-icon>cached</v-icon>
                </v-btn>
            </span>
        </v-col>
    </v-row>
    <v-row dense>
        <!-- Engine -->
        <v-col cols="12" lg="4">            
            <div class="text-caption">
                <v-icon small v-if="engine" color="red">directions_car</v-icon>
                <v-icon small v-else color="green">directions_car</v-icon>&nbsp;
                <span v-if="engine">ENGINE <strong>ON</strong></span>
                <span v-else>ENGINE <strong>OFF</strong></span>
                <p>Since: {{engineSince}} <em>({{engineElapsed}} ago)</em></p>
            </div>
        </v-col>
        <!-- Position -->
        <v-col cols="12" lg="4">            
            <div class="text-caption">
                <v-icon small v-if="signal" color="teal darken-1">signal_cellular_4_bar</v-icon>
                <v-icon small v-else color="teal darken-1">signal_cellular_null</v-icon>&nbsp;
                <span v-if="liveUpdates">POSITION (live updates <strong>on</strong>)</span>
                <span v-else>POSITION (live updates <strong>off</strong>)</span>
                <br/>
                <a v-bind:href="googleMapsURL" target="_blank">Lat.: {{latitude}}, Long.: {{longitude}}</a><br/>
                <span>On: {{locationTimeAsString}} <em>({{locationElapsed}} ago)</em></span><br/>                
            </div>
        </v-col>
        <!-- Stats -->
        <v-col cols="12" lg="4">            
            <div class="text-caption">
                <v-icon small color="teal darken-1">more</v-icon>&nbsp;
                <span>Distance driven: {{distance_driven}} km in {{duration}} sec.</span><br/>
                <span>Max. speed: {{max_speed}} km/h</span><br/>
            </div>
        </v-col>
    </v-row>

    <!-- Location map -->
    <v-row dense>
        <v-col md="auto">
            <div id="map" ref="map"/>
        </v-col>
    </v-row> 
    
    <!-- Intervals -->
    <v-row dense>                
        <v-list dense v-if="intervals && intervals.length > 0">
        <v-list-item v-for="(interval, index) in intervals" :key="index">
          <v-list-item-content>            
            <v-list-item-title>Trip #{{ index + 1 }}</v-list-item-title>
            <v-list-item-subtitle>Time: {{ new Date(interval.StartTime).toLocaleString() }} - {{ interval.Distance }} km </v-list-item-subtitle>            
          </v-list-item-content>
          <v-list-item-action>
            <v-btn small @click="showIntervalDetails(interval)">
              See details
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-alert v-else type="info" dense text>
        No trips.
      </v-alert>
    </v-row>

    <!-- Popup Dialog -->
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-title class="headline">
            <!-- Start time & duration -->
          {{ new Date(selectedInterval.StartTime).toLocaleString() }} - {{ this.diff(selectedInterval.StartTime, selectedInterval.EndTime) }}
        </v-card-title>
        <v-card-text>
          Distance: {{ selectedInterval.Distance }} km <br/>
          Max. speed: {{ selectedInterval.MaxSpeed }} km/h <br/>
          Avg. speed: {{ selectedInterval.AvgSpeed }} km/h <br/>
          GPS locations: {{ selectedInterval.Locations?.length ?? 0 }}
          <div id="intervalmap" ref="intervalmap" style="height: 550px; width: 100%;"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="error" :timeout="5000">
        {{errorMessage}}
        <v-btn color="blue" text @click="error=false">Close</v-btn>
    </v-snackbar>
</v-container>
</template>

<script>
import gmapsInit from '@/utils/googlemaps';

export default {
    name: 'TrackerLocation',
    props: {
        licensePlate: String,
        imei: String,
        date: String
    },
    data: () => ({
        google: null,
        map: null,        
        marker: null,
        ws: null,

        intervalmap: null,

        speed: null,
        latitude: null,
        longitude: null,
        locationTime: null,
        locationTimeAsString: '',
        locationElapsed: 'N/A',

        signal: false,
        engine: false,
        engineTime: null,
        engineSince: 'N/A',
        engineElapsed: 'N/A',
        address: 'Refresh to get the address.',
        geocoding: true,
        liveUpdates: false,
        
        distance_driven: 'N/A',
        duration: 'N/A',
        max_speed: 'N/A', 
        intervals: null,       

        dialog: false,
        selectedInterval: {},
        error: false,
        errorMessage: ''
    }),
    async mounted() {
        try {
            console.log("Initializing Google Maps")
            this.google = await gmapsInit();
            this.map = new this.google.maps.Map(this.$refs["map"], {
                zoom: 16,
                gestureHandling: 'greedy'
            });
        } catch (error) {
            console.error("Error initializing Google Maps: " + error);
        }
        // Display the initial map
        this.retrieveLastLocation()
        this.retrieveStats()
        this.webSocketListener()        
        this.infiniteLoop()
    },
    methods: {
        // Connect to backend and retrieve the last known location
        retrieveLastLocation: function(){
            this.$axios({
                method: 'get',
                url: process.env.VUE_APP_BACKEND_URL + '/lastlocation/' + this.imei
            })
            .then(response => {
                // Last location retrieved successfully
                console.log("Location retrieved for " + this.licensePlate)
                var loc = response.data
                this.processLocation(loc)
            })
            .catch(() => {
                // Last location has failed
                console.log("Location failed for " + this.licensePlate)
            })
            .finally(() => {})
        },
        processLocation: function(loc) {
            console.log(loc)
            this.speed = loc.Speed
            this.latitude = loc.Latitude
            this.longitude = loc.Longitude
            this.locationTime = loc.Time
            this.locationTimeAsString = new Date(loc.Time).toLocaleString()
            this.signal = (loc.SignalStrength === "FULL")
            // Populate engine status
            this.engineTime = loc.LastEngineStartStop
            this.engineSince = new Date(loc.LastEngineStartStop).toLocaleString()
            this.engineElapsed = elapsedTime(this.engineSince)
            switch (loc.TrackerState) {
                case "AUTOLOW":
                case "AUTOSTOP":
                case "LP":
                    this.engine = false
                    break;
                case "AUTO":
                case "AUTOSTART":
                    this.engine = true
                    break;
            }
            this.showLocationOnMap(loc.Latitude, loc.Longitude, loc.Bearing, loc.Speed)
        },
        // Connect to backend and retrieve stats for current date
        retrieveStats: function(){
            var statsURL = process.env.VUE_APP_BACKEND_URL + '/stats/' + this.imei + '/' + this.date;
            console.log("Connecting to " + statsURL)
            this.$axios({
                method: 'get',
                url: statsURL
            })
            .then(response => {
                // Stats retrieved successfully
                console.log("Stats retrieved for " + this.licensePlate)
                var stats = response.data
                this.processStats(stats)
            })
            .catch(() => {
                // Stats has failed
                console.log("Stats failed for " + this.licensePlate)
            })
            .finally(() => {})
        },
        processStats: function(stats) {
            this.distance_driven = stats.Distance;
            this.duration = stats.Duration;
            this.max_speed = stats.MaxSpeed;
            this.intervals = stats.Intervals;            
        },        
        // Show tracker location on the map
        showLocationOnMap: function (latitude, longitude, bearing, speed) {
            // Pan map to the coordinates of the car
            var center = new this.google.maps.LatLng(latitude, longitude);
            this.map.panTo(center);
            // Remove the previous marker (if any)
            if (this.marker != undefined) {
                this.marker.setMap(null);
            }
            // Place a marker on the map
            if (speed <= 2) {
                // If the speed is close to zero, no custom marker is used
                this.marker = new this.google.maps.Marker({
                    position: { lat: latitude, lng: longitude },
                    map: this.map,
                    title: this.licensePlate
                });
            } else {
                // Vehicle is is motion, use a custom marker that is rotated with the bearing (shows the direction of travel)
                this.marker = new this.google.maps.Marker({
                    position: { lat: latitude, lng: longitude },
                    map: this.map,
                    icon: {
                        path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        scale: 6,
                        rotation: bearing
                    },
                    title: this.licensePlate
                });
            }
        },
        showIntervalDetails(selectedInterval) {            
            this.dialog = true;
            this.$nextTick(() => {
                this.showIntervalMap(selectedInterval);
            });
        },
        showIntervalMap(selectedInterval) {
            this.selectedInterval = selectedInterval;
            const bounds = new this.google.maps.LatLngBounds();
            try {
                console.log("Initializing location map")            
                this.intervalmap = new this.google.maps.Map(this.$refs["intervalmap"], {
                    zoom: 16,
                    gestureHandling: 'greedy'
                });
            } catch (error) {
                console.error("Error initializing interval map: " + error);
            }            
            selectedInterval.Locations.forEach(loc => {                
                const marker = new this.google.maps.Marker({
                        position: { lat: loc.Latitude, lng: loc.Longitude },
                        map: this.intervalmap,
                        icon: {
                            path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            scale: 4,
                            rotation: loc.Bearing
                        },
                        title: loc.Speed
                    });                    
            bounds.extend(marker.getPosition());
            });
            this.intervalmap.fitBounds(bounds);
        },
        reverseGeoCode: function(){
            // Reverse geolocate the coordinates to get the address of the car
            this.geocoding = true
            this.$axios({
                method: 'get',
                url: 'https://nominatim.openstreetmap.org/reverse',
                params : {
                    lat: this.latitude,
                    lon: this.longitude,
                    format: 'json',
                }
            })
            .then(result => {
                // Reverse geocoding successful
                this.address = result.data.display_name
            })
            .catch(() => {
                // Reverse geocoding has failed
                this.address = "Error"
            })
            .finally(() => {
                this.geocoding = false
            })
        },
        diff: function(from, to) {
            return diffTime(from, to)
        },
        // Web socket handler (for live location updates)
        webSocketListener: function() {
            // Open the web socket
            this.ws = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL + "/" + this.imei);
            this.ws.onopen = this.ws_onopen;
            this.ws.onerror = this.ws_onerror;        
            this.ws.onmessage = this.ws_onmessage;
            this.ws.onclose = this.ws_onclose;
        },

        ws_onopen: function(){
            // Web Socket is connected
            console.log("Web socket connected for " + this.licensePlate)
            this.errorMessage = "Live map updates are enabled"
            this.error = true
            this.liveUpdates = true
        },

        ws_onerror: function(e) {
            console.log("Web socket error: " + e)
        },

        ws_onmessage: function (evt) {
            // Location update received via websocket
            var loc = JSON.parse(evt.data);
            console.log("Received location update: " + evt.data);
            // Update the map accordingly
            this.processLocation(loc)
        },

        ws_onclose: function(evt) { 
            console.log("Web socket closed for " + this.licensePlate + " with code " + evt.code)
            this.errorMessage = "Connection to server lost. Retrying in 15 sec."
            this.error = true
            this.liveUpdates = false
            setTimeout(this.webSocketListener, 15000)
        },

        // Infinite loop
        infiniteLoop: function() {
            setTimeout(this.infiniteLoop, 1000); // check again in a second
            this.locationElapsed = elapsedTime(this.locationTime)
            this.engineElapsed = elapsedTime(this.engineTime)
        }        
    },
    computed: {
        googleMapsURL: function() {
            return "https://www.google.com/maps/search/?api=1&query=" + this.latitude + "," + this.longitude
        },                
    }
}

function diffTime(from, to) {
  var startTime = new Date(from);
  var endTime = new Date(to);
  var sec_num = Math.round((endTime.getTime() - startTime.getTime()) / 1000);

  // Split time difference into days/hours/minutes/seconds
  var days = Math.floor(sec_num / 86400);
  sec_num = sec_num - days * 86400;
  var hours = Math.floor(sec_num / 3600);
  sec_num = sec_num - hours * 3600;
  var minutes = Math.floor(sec_num / 60);
  sec_num = sec_num - minutes * 60;
  var seconds = sec_num;

  var result = "";
  if (days > 0) {
    result += (days < 10 ? "0" : "") + days + "d ";
    result += (hours < 10 ? "0" : "") + hours + "h ";
    result += (minutes < 10 ? "0" : "") + minutes + "m ";
    result += (seconds < 10 ? "0" : "") + seconds + "s";
  } else if (hours > 0) {
    result += (hours < 10 ? "0" : "") + hours + "h ";
    result += (minutes < 10 ? "0" : "") + minutes + "m ";
    result += (seconds < 10 ? "0" : "") + seconds + "s";
  } else if (minutes > 0) {
    result += (minutes < 10 ? "0" : "") + minutes + "m ";
    result += (seconds < 10 ? "0" : "") + seconds + "s";
  } else {
    result += (seconds < 10 ? "0" : "") + seconds + "s";
  }

  return result.trim();
}

function elapsedTime(since) {
    // Display the time when the page was last refreshed    
    var startTime = new Date(since);
    var endTime = new Date();
    return diffTime(startTime, endTime);
}
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

#map {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
}
</style>